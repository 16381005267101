module.exports = {
  model: { id: '', name: '' },
  schema: {
    fields: [
      {
        type: 'custom-input',
        options: { uppercase: true },
        label: 'Nome',
        model: 'name',
        placeholder: 'Informe um serviço autorizado',
        required: true,
      },
    ],
  },
  columns: [{ key: 'name', label: 'Nome', filter: true }],
  viewDataTypes: [{ key: 'name', label: 'Nome' }],
}
