<template>
  <div class="home">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Novo serviço autorizado</span>
    </button>
    <b-table
      :data="allAuthorizedServicesTemp"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="ID"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.id }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.id }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.name.toUpperCase() }}</b-table-column
      >

      <b-table-column
        field="price"
        label="Preço"
        sortable
        :searchable="true"
        v-slot="props"
        >R$ {{ props.row.price }}</b-table-column
      >

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Nome:</strong>
                <small>{{ props.row.name }}</small>
                <br />
                <strong>Preço:</strong>
                <small>{{ props.row.price }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input v-model="model.name" required></b-input>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.price"
              ></b-input>
            </b-field>
            <b-button type="button field is-info" class="mt-10" @click="save"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/authorized-services/store/service'
import AuthorizedService from '../models/authorized-service'

export default {
  name: 'AuthorizedServices',
  data() {
    return {
      model: AuthorizedService.model,
      allAuthorizedServicesTemp: [],
      isModalVisible: false,
      showDetailIcon: false,
      isCardModalActive: false,
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('authorizedServices', ['allAuthorizedServices']),
  },
  methods: {
    ...mapActions('authorizedServices', ['getAllAuthorizedServices']),
    async init() {
      await this.getAllAuthorizedServices()

      this.allAuthorizedServicesTemp = this.allAuthorizedServices.map((x) => {
        return {
          ...x,
          price: parseFloat(x.price).toFixed(2),
        }
      })
    },
    async save() {
      const authorizedService = { ...this.model }

      authorizedService.price = authorizedService.price.replace('R$ ', '')
      authorizedService.price = authorizedService.price.replace(',', '.')

      try {
        const save = authorizedService.id
          ? await service.updateAuthorizedService(authorizedService)
          : await service.saveAuthorizedService(authorizedService)

        await this.$success('Serviço Autorizado')
        await location.reload(true)
      } catch (error) {
        console.log('error', error)
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask(
          'Você deseja remover o serviço autorizado?'
        )

        if (result.isConfirmed) {
          await service.deleteAuthorizedService(id)

          await this.$delete('Serviço Autorizado')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    onEdit(item) {
      this.model = { ...item }
    },
  },
}
</script>

<style scoped></style>
